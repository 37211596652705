.container {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.textAndImage {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 480px;
  overflow: hidden;
  align-items: center;
  position: relative;
}

.overlay {
  position: absolute;
  height: 600px;
  width: 100%;
  opacity: 0.5;
  background: linear-gradient(black, grey);
  z-index: 2;
}

.textContainer {
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
  text-align: left;
  flex: 1;
  width: 80%;
  bottom: 15%;
  z-index: 2;
}
.descriptorAlignment{
  text-align: center;
}

.imageContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative;
}

.image {
  height: 100%;
  width: auto;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.changeImageContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.changeSlideButton {
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 2px solid var(--primary-color);
}

.stripeAndButtonContainer {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
}

.pinkStripe {
  display: none;
}

.dots {
  background-color: var(--primary-color);
  height: 8px;
  width: 8px;
  border-radius: 5px;
  margin-left: 10px;
}

.notVisited {
  background-color: grey;
}

@media screen and (min-width: 768px) {
  .image{
    width: 100%;
    height: 100%;
  }
  .textContainer {
    text-align: center;
    width: 50%;
    bottom: 30%;
  }
}

@media screen and (min-width: 1024px) {
  .textAndImage {
    flex-direction: row;
    height: 70%;
  }

  .overlay {
    display: none;
  }

  .textContainer {
    position: relative;
    color: black;
    flex-basis: 45%;
    text-align: left;
    flex-direction: column;
    bottom: unset;
  }
  .headerText {
    padding-right: 5%;
  }

  .subText {
    margin-right: auto;
    padding-right: 5%;
  }
  .descriptorAlignment{
    text-align: left;
  }

  .imageContainer {
    flex-basis: 57%;
  }

  .pinkStripe {
    display: flex;
    width: 60%;
    background-color: var(--primary-color);
  }
  .stripeAndButtonContainer {
    height: 25%;
  }
}
@media screen and (min-width: 1366px) {
  .container {
    height: 800px;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    height: 950px;
  }
}
@media screen and (min-width: 1920px) {
  .container {
    height: 1200px;
  }
}
